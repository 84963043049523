var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-producer" }, [
    _c("header", { staticClass: "header header--borderBottom" }, [
      _c("div", { staticClass: "container headerContainer" }, [
        _vm._m(0),
        _vm.authenticatedUser
          ? _c("div", { staticClass: "headerContainer__alignRight" }, [
              _vm.hasEliteSubscription
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn--danger btn--sm",
                      attrs: { href: _vm.elitepageLink, target: "_blank" },
                    },
                    [_vm._v("CUSTOMIZE ELITEPAGE DESIGN")]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "section" },
      [
        _vm.isLoading || !_vm.elitepage
          ? _c(
              "div",
              { staticClass: "container container--fullWidth" },
              [_c("VLoadSpinner")],
              1
            )
          : !_vm.isLoading && !_vm.hasEliteSubscription
          ? _c("AlertBoxSubscription")
          : [
              _c(
                "div",
                {
                  staticClass:
                    "container container--fullWidth grid grid__col-small-big",
                },
                [
                  _vm._m(1),
                  _c(
                    "div",
                    { staticClass: "wrapper--filled wrapper--paddingLg" },
                    [
                      _c("div", { staticClass: "formGroup" }, [
                        _c("label", { staticClass: "formLabel" }, [
                          _vm._v("Elitepage Link"),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "formInputWithCopyBtn formInput formInput--fullWidth",
                            attrs: { name: "email", type: "text" },
                          },
                          [
                            _c("p", [_vm._v(_vm._s(_vm.elitepageLink))]),
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "clipboard",
                                    rawName: "v-clipboard:copy",
                                    value: _vm.elitepageLink,
                                    expression: "elitepageLink",
                                    arg: "copy",
                                  },
                                ],
                                staticClass: "btn btn--primary btn--xs",
                              },
                              [_vm._v("Copy link")]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "btn btn--black btn--xs",
                                attrs: {
                                  href: _vm.elitepageLink,
                                  target: "_blank",
                                },
                              },
                              [_vm._v("Visit & Edit")]
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "formGroup" }, [
                        _c("label", { staticClass: "formLabel" }, [
                          _vm._v("Landing Page Link"),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "formInputWithCopyBtn formInput formInput--fullWidth",
                            attrs: { name: "email", type: "text" },
                          },
                          [
                            _c("p", [_vm._v(_vm._s(_vm.mailingListPageLink))]),
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "clipboard",
                                    rawName: "v-clipboard:copy",
                                    value: _vm.mailingListPageLink,
                                    expression: "mailingListPageLink",
                                    arg: "copy",
                                  },
                                ],
                                staticClass: "btn btn--primary btn--xs",
                              },
                              [_vm._v("Copy link")]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "btn btn--black btn--xs",
                                attrs: {
                                  href: _vm.mailingListPageLink,
                                  target: "_blank",
                                },
                              },
                              [_vm._v("Visit & Edit")]
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "formGroup" }, [
                        _c("label", { staticClass: "formLabel" }, [
                          _vm._v("Main Offer Page Link"),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "formInputWithCopyBtn formInput formInput--fullWidth",
                            attrs: { name: "email", type: "text" },
                          },
                          [
                            _c("p", [_vm._v(_vm._s(_vm.productPage))]),
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "clipboard",
                                    rawName: "v-clipboard:copy",
                                    value: _vm.productPage,
                                    expression: "productPage",
                                    arg: "copy",
                                  },
                                ],
                                staticClass: "btn btn--primary btn--xs",
                              },
                              [_vm._v("Copy link")]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "btn btn--black btn--xs",
                                attrs: {
                                  href: _vm.productPage,
                                  target: "_blank",
                                },
                              },
                              [_vm._v("Visit & Edit")]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "container container--fullWidth grid grid__col-small-big",
                },
                [
                  _vm._m(2),
                  _c(
                    "div",
                    { staticClass: "wrapper--filled wrapper--paddingLg" },
                    [
                      _c("div", { staticClass: "formGroup" }, [
                        _c("label", { staticClass: "formLabel" }, [
                          _vm._v("YouTube Channel"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.youtubeChannelId,
                              expression: "youtubeChannelId",
                            },
                          ],
                          staticClass: "formInput formInput--fullWidth",
                          attrs: {
                            type: "text",
                            placeholder:
                              "https://www.youtube.com/channel/UC8_P91A7k1Z3I9CdKzkCdp2",
                          },
                          domProps: { value: _vm.youtubeChannelId },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.youtubeChannelId = $event.target.value
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "formGroup" }, [
                        _c("label", { staticClass: "formLabel" }, [
                          _vm._v("YouTube Video"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.youtubeVideoLink,
                              expression: "youtubeVideoLink",
                            },
                          ],
                          staticClass: "formInput formInput--fullWidth",
                          attrs: {
                            type: "text",
                            placeholder:
                              "https://www.youtube.com/watch?v=hG4lT4fxj8M",
                          },
                          domProps: { value: _vm.youtubeVideoLink },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.youtubeVideoLink = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "container container--fullWidth grid grid__col-small-big",
                },
                [
                  _vm._m(3),
                  _c(
                    "div",
                    { staticClass: "wrapper--filled wrapper--paddingLg" },
                    [
                      _c("div", { staticClass: "formGroup" }, [
                        _c("label", { staticClass: "formLabel" }, [
                          _vm._v("Facebook Pixel ID"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.elitepage.facebook_pixel_id,
                              expression: "elitepage.facebook_pixel_id",
                            },
                          ],
                          staticClass: "formInput formInput--fullWidth",
                          attrs: {
                            type: "text",
                            placeholder: "Enter Facebook Pixel ID",
                          },
                          domProps: { value: _vm.elitepage.facebook_pixel_id },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.elitepage,
                                "facebook_pixel_id",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("hr", { staticClass: "form__divider h-margin-30" }),
                      _c(
                        "div",
                        { staticClass: "formGroup formGroup--horizontal" },
                        [
                          _c("p", { staticClass: "textSizeSm" }, [
                            _vm._v("Show total Page Views & Plays"),
                          ]),
                          _c("toggle-button", {
                            staticClass: "vue-js-switch--margin",
                            attrs: { color: "#0269FF", labels: true },
                            model: {
                              value: _vm.elitepage.show_analytics,
                              callback: function ($$v) {
                                _vm.$set(_vm.elitepage, "show_analytics", $$v)
                              },
                              expression: "elitepage.show_analytics",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "container container--fullWidth grid grid__col-small-big",
                },
                [
                  _vm._m(4),
                  _c(
                    "div",
                    { staticClass: "wrapper--filled wrapper--paddingLg" },
                    [
                      _c("div", { staticClass: "formGroup" }, [
                        _c("label", { staticClass: "formLabel" }, [
                          _vm._v("Title"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.elitepage.seo_title,
                              expression: "elitepage.seo_title",
                            },
                          ],
                          staticClass: "formInput formInput--fullWidth",
                          attrs: {
                            type: "text",
                            placeholder: "Enter Elite page title",
                          },
                          domProps: { value: _vm.elitepage.seo_title },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.elitepage,
                                "seo_title",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "formGroup" }, [
                        _c("label", { staticClass: "formLabel" }, [
                          _vm._v("Description"),
                        ]),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.elitepage.seo_description,
                              expression: "elitepage.seo_description",
                            },
                          ],
                          staticClass:
                            "formInput formInput--textarea formInput--fullWidth",
                          attrs: {
                            type: "text",
                            placeholder: "Enter Elite page description",
                          },
                          domProps: { value: _vm.elitepage.seo_description },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.elitepage,
                                "seo_description",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "formGroup" }, [
                        _c("label", { staticClass: "formLabel" }, [
                          _vm._v("Keywords"),
                        ]),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.elitepage.seo_keywords,
                              expression: "elitepage.seo_keywords",
                            },
                          ],
                          staticClass:
                            "formInput formInput--textarea formInput--fullWidth",
                          attrs: {
                            type: "text",
                            placeholder: "beats, hiphop, rap",
                          },
                          domProps: { value: _vm.elitepage.seo_keywords },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.elitepage,
                                "seo_keywords",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
      ],
      2
    ),
    _c("div", { staticClass: "fabWrapper fabWrapper--right" }, [
      _c(
        "a",
        { staticClass: "btn btn--primary", on: { click: _vm.updateElitePage } },
        [_vm._v("SAVE CHANGES")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "headerContainer__heading" }, [
      _c("h1", [_vm._v("Elite Profile")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "h-margin-30" }, [
      _c("h2", [_vm._v("Pages URLs")]),
      _c("p", [_vm._v("Access and share your page with others")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "h-margin-30" }, [
      _c("h2", [_vm._v("YouTube")]),
      _c("p", [
        _vm._v(
          "Connect your Channel and select a video to display in your page"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "h-margin-30" }, [
      _c("h2", [_vm._v("Analytics")]),
      _c("p", [_vm._v("Show your data & add Facebook Pixel tracking")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "h-margin-30" }, [
      _c("h2", [_vm._v("Page Settings")]),
      _c("p", [_vm._v("Set your Elite profile SEO and metadata")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }